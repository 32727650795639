import React from "react"
import ReactGA from "react-ga"
import { useState, useEffect } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Container from "../components/common/Container"
import Header from "../components/Header"
import Social from "../components/common/Social"
import overlayIllustration from "../assets/illustrations/overlay.svg"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
// import Snackbar from "@material-ui/core/Snackbar"
// import Alert from "@material-ui/lab/Alert"
import { StaticImage } from "gatsby-plugin-image"
import { ReactTyped } from "react-typed"

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    paddingBottom: "4rem",
    backgroundImage: `url(${overlayIllustration})`,
    backgroundSize: "contain",
    backgroundPosition: "right top",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.common.primary,
  },
  button: {
    ...theme.typography.buttonText,
    backgroundColor: "#0074d9",
    color: "#fff",
    padding: "0.7rem 2rem",
    opacity: 0.85,
    "&:hover": {
      opacity: 1,
      backgroundColor: "#0074d9",
    },
  },
  illustration: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
      marginTop: "2rem",
    },
  },
  heading: {
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30pt",
    },
  },
  tagline: {
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24pt",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18pt",
    },
  },
  alert: {
    width: "60vw",
  },
  imageContainer: {
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: "5em",
    },
  },
  typingContainer: {
    height: "10em",
    [theme.breakpoints.down("xs")]: {
      height: "8em",
    },
  },
  sectionGridContainer: {
    marginTop: "13em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6em",
    },
    marginBottom: "6.5em",
  },
  socialIconContainer: {
    margin: "0 1rem",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0.5rem",
    },
  },
}))

const Intro = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smaller = useMediaQuery(theme.breakpoints.down("sm"))
  const [previousUrl, setPrevious] = useState("")
  useEffect(() => {
    if (previousUrl !== window.location.pathname) {
      setPrevious(window.location.pathname)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [previousUrl])

  //--use the following logic when site is under construction--//
  // const [open, setOpen] = useState(true)

  // const handleClose = (_, reason) => {
  //   if (reason === "clickaway") return
  //   setOpen(false)
  // }

  return (
    <div className={classes.sectionWrapper}>
      {/* <Snackbar
        open={open}
        autoHideDuration={null}
        onClose={handleClose}
        style={{ width: smaller ? "95vw" : "60vw" }}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          elevation={8}
          style={{ fontSize: "1rem" }}
        >
          This site is under construction. Some features might not work as
          expected. Sorry for the inconvinence.
        </Alert>
      </Snackbar> */}
      <Header />
      <div>
        <Container>
          <Grid
            container
            alignItems="center"
            direction={smaller ? "column" : "row"}
            justifyContent="space-between"
            className={classes.sectionGridContainer}
          >
            <Grid item container sm={12} md={6}>
              <Grid item>
                <Typography variant="h1" className={classes.heading}>
                  Hi There!
                </Typography>
              </Grid>
              <Grid item container className={classes.typingContainer}>
                <Typography variant="h3" className={classes.tagline}>
                  <ReactTyped
                    strings={[
                      "I'm Hassaan and I'm a Web Engineer!",
                      "I build websites and web applications.",
                      "I convert designs to products with pixel perfect accuracy.",
                      "I focus on performance and efficiency.",
                    ]}
                    typeSpeed={40}
                    backSpeed={30}
                    backDelay={1000}
                    loop
                  />
                </Typography>
              </Grid>
              <Grid item container alignItems="stretch">
                <Grid item>
                  <Button
                    variant="contained"
                    component={AnchorLink}
                    href="#contact"
                    className={classes.button}
                  >
                    GET IN TOUCH
                  </Button>
                </Grid>
                <Grid item className={classes.socialIconContainer}>
                  <Social />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={6} className={classes.imageContainer}>
              <StaticImage
                src="../assets/illustrations/dev.svg"
                alt="I’m Hassaan and I’m a Web engineer!"
                placeholder="blurred"
                className={classes.illustration}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default Intro
