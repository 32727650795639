import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Container from "../components/common/Container"
import aboutIllustration from "../assets/illustrations/details.svg"
import aboutContainer from "../assets/illustrations/skills.svg"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    backgroundImage: `url(${aboutIllustration})`,
    backgroundSize: "contain",
    backgroundPosition: "left top",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.common.primary,
  },
  button: {
    ...theme.typography.buttonText,
    backgroundColor: "#0074d9",
    color: "#fff",
    padding: "0.7rem 2rem",
    opacity: 0.85,
    "&:hover": {
      opacity: 1,
      backgroundColor: "#0074d9",
    },
  },
  bodyText: {
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16pt",
    },
  },
  avatar: {
    height: 350,
    width: 400,
    marginBottom: "3.5rem",
    [theme.breakpoints.down("xs")]: {
      height: "15rem",
      width: "85%",
    },
  },
  imageWrapper: {
    backgroundImage: `url(${aboutContainer})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.common.primary,
  },
}))

const About = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smaller = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.sectionWrapper}>
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          direction={smaller ? "column" : "row"}
          style={{ padding: "4rem 0", marginTop: "7.5em", marginBottom: "5em" }}
        >
          <Grid
            item
            sm={12}
            md={6}
            align="center"
            style={{ paddingRight: smaller ? "0rem" : "5rem" }}
          >
            <div className={classes.imageWrapper}>
              <Avatar variant="rounded" className={classes.avatar}>
                <StaticImage
                  src="../assets/images/founder.jpg"
                  alt="Hassaan's picture"
                  placeholder="blurred"
                />
              </Avatar>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant="h2" gutterBottom>
              About Me
            </Typography>
            <Typography
              varinat="body1"
              className={classes.bodyText}
              gutterBottom
            >
              I help businesses and individuals converting high fidelity designs
              to functional and scalable digital products. I focus on usability,
              accessibility, automation, and performance when it comes to
              websites and web-applications. I achieve all this by using the
              power of modern tried and tested web technologies. So let's
              connect to craft a tailored web solution to your business needs.
            </Typography>
            <Button
              variant="contained"
              component={AnchorLink}
              href="#contact"
              className={classes.button}
            >
              GET IN TOUCH
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default About
