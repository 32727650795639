import React, { useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/CloseOutlined"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import sections from "./sections.json"

const useStyles = makeStyles(theme => ({
  drawer: {
    backgroundColor: theme.palette.primary.light,
    width: "30vw",
    [theme.breakpoints.down("xs")]: {
      width: "40vw",
    },
  },
  drawerItem: {
    opacity: 0.85,
    "&:hover": {
      opacity: 1,
    },
  },
  drawerItemText: {
    ...theme.typography.tab,
    textTransform: "capitalize",
  },
  drawerItemSelected: {
    opacity: 0.85,
  },
  toolbarMargin: {
    //toolbar minimum height
    ...theme.mixins.toolbar,
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
    marginRight: "0.75em",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0.5em",
    },
  },
  closeIconContainer: {
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "1.5em",
    width: "1.5em",
  },
}))

const Drawer = () => {
  const classes = useStyles()
  const [state, setState] = useState(false)
  return (
    <>
      <SwipeableDrawer
        disableBackdropTransition
        disableDiscovery
        open={state}
        onClose={() => setState(false)}
        onOpen={() => setState(true)}
        anchor="right"
        classes={{ paper: classes.drawer }}
      >
        <IconButton
          onClick={() => setState(false)}
          className={classes.closeIconContainer}
        >
          <CloseIcon className={classes.drawerIcon} htmlColor="#212121" />
        </IconButton>
        <List>
          {sections.map(section => (
            <ListItem
              key={section.activeTabIndex}
              button
              divider
              component={AnchorLink}
              href={section.link}
              classes={{
                selected: classes.drawerItemSelected,
                root: classes.drawerItem,
              }}
              onClick={() => setState(false)}
            >
              <ListItemText
                className={classes.drawerItemText}
                disableTypography
              >
                {section.label}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      {state ? null : (
        <IconButton
          onClick={() => setState(true)}
          className={classes.drawerIconContainer}
          disableRipple
        >
          <MenuIcon className={classes.drawerIcon} htmlColor="#212121" />
        </IconButton>
      )}
    </>
  )
}

export default Drawer
